import type { ItemModel } from './types.ts';

import DataItem from './DataObject.Item.ts';
import { fileUtils } from 'o365-utils';
import { getDataObjectById } from './store.ts';

const { isMedia, safeEscapeFileName } = fileUtils;

declare module 'o365.modules.DataObject.ts' {
    interface DataItem<T extends ItemModel = ItemModel> {
        getLink: File<T>['getLink'];
    }
}

Object.defineProperties(DataItem.prototype, {
    'file': {
        get() {
            if (this.file == null) {
                this.file = new File(this);
            }
            return this.file;
        }
    }  
});


export default class File<T extends ItemModel = ItemModel> {
    private _dataItem: DataItem<T>;

    constructor(pDataItem: DataItem<T>) {
        this._dataItem = pDataItem;
    }
    getLink(isDownload = false, originalFile = false) {
        // if (props.offline) {
        //return getOfflineLink(row, isDownload, originalFile);
        //}

        // const { primKey, FileName } = this._dataItem;

        const FileName = this._dataItem.item['FileName'];
        const PrimKey = this._dataItem.item['PrimKey'];

        let urlBase = '/nt/api';

        if (isMedia(FileName) || originalFile) {
            urlBase = '/nt/api/file';
        }
        const dataObject = getDataObjectById(this._dataItem.dataObjectId)
        const viewName = dataObject.viewName;

        let fileOrigin = '';

        if (isMedia(FileName)) {
            fileOrigin = isDownload ? 'download' : 'view';
        } else {
            if (isDownload) {
                fileOrigin = originalFile ? 'download' : 'download-pdf';
            } else {
                fileOrigin = 'view-pdf';
            }
        }

        return [urlBase, fileOrigin, viewName, PrimKey, safeEscapeFileName(FileName)].join('/');
    }
}
